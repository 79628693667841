import ImageGallery from "react-image-gallery";
import Page from "../components/page";
import PropTypes from "prop-types";
import React from "react";
import SEO from "../components/seo";
import { graphql } from "gatsby";

const WerkwijzePage = ({ data }) => {
  const slider = data.ruimtes.edges.map(ruimte => {
    return {
      original: ruimte.node.file.url,
      originalAlt: ruimte.node.title,
      originalTitle: ruimte.node.title,
      thumbnail: ruimte.node.file.url,
      thumbnailAlt: ruimte.node.title,
      thumbnailTitle: ruimte.node.title
    };
  });

  return (
    <Page>
      <SEO title='Werkwijze' />
      <div className='container px-8 md:flex mb-12 lg:mb-16 mt-4 lg:mt-16'>
        <div className='md:w-1/3'>
          <img
            className='mb-4 md:mb-0 w-full'
            loading='lazy'
            src={data.image.file.url}
            alt={data.image.title}
            title={data.image.title}
          />
        </div>
        <div className='md:pl-8 lg:pl-16 w-full md:w-2/3'>
          <h2 className='title mb-6'>Werkwijze</h2>
          <div className='wysiwyg mb-6'>
            <p>
              U kan met ons <b>contact</b> opnemen via mail of telefoon.
            </p>
            <p>
              In een <b>eerste gesprek</b> maken we kennis en proberen we zicht
              te krijgen op uw vraag en wie u bent. Op het einde van dit eerste
              gesprek, kijken we wat er verder kan. In therapie is de
              behandeling van belang maar ook of u zich goed voelt in het
              contact.
            </p>
            <p>
              Indien zou blijken na een eerste gesprek dat u beter door iemand
              anders kan geholpen worden verwijzen we u graag actief door.
            </p>
            <p>
              We werken samen met uw huisarts indien u dit wenst en/of met
              andere hulpverleners.
            </p>
            <p>
              De praktijk werkt nauw samen met de huisartsengroep Groenenborg
              maar staat open voor iedereen
            </p>
            <p>
              Vastgelegde afspraken kunnen tot 24 uur op voorhand kosteloos
              geannuleerd of verzet worden. Als u ons tijdig verwittigt, helpt u
              andere cliënten. Vrijgekomen afspraken kunnen dan nog voor anderen
              benut worden. Bij een latere annulering zal het volledige bedrag
              in rekening worden gebracht.
            </p>
            <p>
              Wij zijn erkend door de verschillende mutualiteiten waardoor u
              recht kan hebben op een beperkte tussenkomst voor psychotherapie.
            </p>
            <p>Meer informatie vindt u bij uw mutualiteit.</p>
          </div>
        </div>
      </div>
      <div className='container px-8 mb-12 lg:mb-20'>
        <ImageGallery
          items={slider}
          lazyLoad={true}
          showThumbnails={false}
          autoPlay={true}
          showPlayButton={false}
          slideInterval={6000}
        />
      </div>
    </Page>
  );
};

WerkwijzePage.propTypes = {
  data: PropTypes.object.isRequired
};

export default WerkwijzePage;

export const pageQuery = graphql`
  query {
    image: contentfulAsset(contentful_id: { eq: "6lfA8uN7XVfJ2kntKFnovw" }) {
      title
      file {
        url
      }
    }
    ruimtes: allContentfulAsset(filter: { title: { regex: "/ruimtes/" } }) {
      edges {
        node {
          title
          file {
            url
          }
        }
      }
    }
  }
`;
